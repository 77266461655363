"use client"
import { intersection, isEmpty } from "lodash"
import React, { createContext, useCallback, useEffect, useState } from "react"
import { useQuery } from "urql"

export interface FeatureFlagContextProps {
  featureFlags: string[]
  isEnabled: (flags: string[]) => boolean
  loaded: boolean
}
export interface FeatureFlagProviderProps {
  children: React.ReactNode
  query: string
}
export const FeatureFlagContext = createContext<FeatureFlagContextProps>({
  featureFlags: [],
  isEnabled: () => true,
  loaded: true,
})
export const FeatureFlagProvider = ({
  children,
  query, // due to query defined in the app, we need to pass it in as a prop
}: FeatureFlagProviderProps) => {
  const [{ data, fetching }] = useQuery({
    query,
  })
  const [featureFlags, setFeatureFlags] = useState([])
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    if (data && data.getFeatureFlags) {
      setFeatureFlags(data.getFeatureFlags)
      setLoaded(true)
    }
  }, [data, fetching])

  const isEnabled = useCallback(
    (flags: string[]) => {
      if (isEmpty(flags)) return true // if no flags are passed in, default to true
      return !isEmpty(intersection(featureFlags, flags))
    },
    [featureFlags],
  )

  return (
    <FeatureFlagContext.Provider
      value={{
        featureFlags,
        isEnabled,
        loaded,
      }}
    >
      {children}
    </FeatureFlagContext.Provider>
  )
}
