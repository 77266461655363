"use client"

import { CircularProgress, Stack } from "@mui/material"
import { notFound } from "next/navigation"
import { useContext } from "react"
import { FeatureFlagContext } from "./FeatureFlagProvider"

export interface FeatureFlagProps {
  children: React.ReactNode
  flags: string[]
  fallback?: React.ReactNode
}
export const FeatureFlag = (props: FeatureFlagProps) => {
  const { children, flags, fallback } = props
  const { isEnabled, loaded } = useContext(FeatureFlagContext)
  const enabled = isEnabled(flags)

  if (!loaded) {
    return (
      <Stack
        sx={{ width: "100%" }}
        alignItems="center"
        justifyContent="center"
        flexGrow={1}
      >
        <CircularProgress />
      </Stack>
    )
  }

  if (loaded && !enabled && !fallback) {
    notFound()
  }
  return enabled ? children : fallback
}
